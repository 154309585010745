/* colors!!! */
/* bluegreen = #074F57
darkgrey = 474448
purple = 56445D
green = A1C181
tan = BFC3BA */

*{

  font-family: 'Raleway', sans-serif;
}
.navRoot {
  /* border: 1px solid red; */
  background-color: #eae0d5;
  height: 180px;
  border-bottom: 10px solid #0a0908;
  display: grid;
  grid-template-columns: 25% 75% a;

}
.linkBoxRoot{
  background-color: #22333b;
  display: flex;
  justify-content: center;
}
.linkbox{
  width: 40%;
  background-color: #22333b;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #eae0d5;
}

.linkbox a {
  text-decoration: none;
  color: inherit;
  font-size: 50px;
  opacity: .8;
}
.linkbox a:hover {
 opacity: 1;
}
.navPhoto {
  /* border: 1px solid red; */
  grid-column: 0/1;
  position: absolute; 
  overflow: hidden;
  margin-top: 75px;
  margin-left: 40px;
  display: flex;
  border-radius: 50%;
}

#navPhoto {
  flex-shrink: 0;
  max-width: 200px;
  max-height: 200px;
  border: 10px solid #0a0908;
  border-radius: 50%;
}

.navBody {
  /* border: 2px solid blue; */
  display: flex;
}

h2 {
  margin-top: 25px;
  }


.projectRoot{
  background-color: #22333b;
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FCFCFC;
  text-shadow:1px 1px 1px rgba(0, 0, 0, 0.815);
  font-weight: 100;
}

.projectCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid greenyellow; */
  width: 100%;
  margin: 15px;
  height: 100%;
}

#navName {
  /* border: 1px solid red; */
  flex: 1;
  background-color: #eae0d5;
  color: #312D31;
  font-size: 50px;
}
.mobileDescript {
  display: none;
}
#name {
  /* border: 1px solid red; */
  margin: 14px;
  background-color: inherit;
  text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.719);
}

#descript {
  /* border: 1px solid red; */
  font-size: 50%;
  margin-top: 45px;
  margin-left: 260px;
  color: #312D31;
  background-color: inherit;
  text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.719);
  position: absolute;

}
.cardRoot {
  margin: 20px;
  max-width: 780px;
  height: 400px;
  display: flex;
}
.cardMedia {
  height: 400px;
  min-width: 300px;
}

@media (max-width: 625px) {
#name {
  font-size: smaller;
}
#descript {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 240px;
}

h2{
  margin-top: 40px;
}
.linkbox {
  margin-top: 95px;
  width: 100%;
}
.cardMedia {
  min-width: 140px;
}
}

@media (max-width: 420px) {

  #descript {
    display: none;
  }
  .linkbox {
    margin-top: 5px;
  }
  #name {
    font-size: 35px;
  }
  .navPhoto{
    margin-left: 21%;
  }
  .mobileDescript {
    height: 180px;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    background-color: #22333b;
    color: #FCFCFC;
  }
  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

}